import sal from 'sal.js'
import domReady from "@wordpress/dom-ready";

// Import the stylesheet for SAL
import 'sal.js/src/sal.scss';

console.log({ domReady });

domReady( () => {
	sal();
} );
